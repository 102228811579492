<template>
  <b-modal active @close="close">
    <div class="template-add">
      <div class="template-add-header">
        <p class="template-add-header-text">
          {{
            isEdit
              ? 'Edit portfolio content section'
              : 'Add content from portfolio'
          }}
        </p>
        <Button
          icon="close"
          type="grey"
          size="xs"
          @click="() => $emit('close')"
        />
      </div>
      <div v-if="!step" class="template-add-content first">
        <p class="template-add-content-header">Content type</p>
        <div class="template-add-content-options">
          <div
            v-for="t in ori_types"
            :key="t.key"
            class="template-add-content-options-option"
            :class="{ selected: t.key === type }"
            @click="() => selectType(t)"
          >
            <Checkbox
              :value="t.key === type"
              type="radio"
              class="template-add-content-options-option-radio"
              @click.native.stop
            />
            <div class="template-add-content-options-option-info">
              <p class="template-add-content-options-option-title">
                {{ t.title }}
              </p>
              <p class="template-add-content-options-option-description">
                {{ t.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="template-add-content">
        <p class="template-add-content-text">
          Select one or more portfolio templates to add to this meeting
          template. Reorder them as needed. We will insert the first available
          template in the list for the selected portfolio item in the meeting
          outputs.
        </p>
        <DraggableList
          v-if="selected.length"
          :items="selected"
          class="template-add-content-selected"
          @reorder="handleReorderSelected"
        >
          <template #item="{ item, index }">
            <div class="template-add-content-selected-item">
              <img
                :src="
                  require(`@/assets/file_type/${
                    isPresentation(item) ? 'powerpoint' : 'word'
                  }.svg`)
                "
                alt=""
                class="template-add-content-selected-item-icon"
              />
              <div class="template-add-content-selected-item-name">
                {{ item.name }}
              </div>
              <Button
                icon="bin"
                type="white"
                @click="() => removeSelected(index)"
              />
            </div>
          </template>
        </DraggableList>
        <div class="template-add-content-list">
          <TemplateCard
            v-for="template in filteredTemplates"
            :key="template.uuid"
            :template="template"
            :show-source="false"
          >
            <template #action>
              <Button
                text="Add"
                size="xs"
                icon="plus-medium"
                @click="() => selectTemplate(template)"
              />
            </template>
          </TemplateCard>
          <div v-if="loading" class="template-add-content-list-loading">
            <img
              src="@/assets/icons/spinner.svg"
              alt=""
              class="template-add-content-list-loading-icon"
            />
            Loading templates
          </div>
          <p
            v-else-if="!filteredTemplates || !filteredTemplates.length"
            class="template-add-content-list-empty"
          >
            No{{ selectableTemplates.length ? ' more' : '' }} templates
            available
          </p>
        </div>
      </div>
      <div class="template-add-footer">
        <Button
          v-if="!isEdit"
          :text="step ? 'Change type' : 'Cancel'"
          type="white"
          @click="close"
        />
        <Button
          :text="submitButton"
          :disabled="submitDisabled"
          @click="submit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getKeyContentTemplates } from '@/services/keyContentService'
import Checkbox from '@c/library/Checkbox.vue'
import DraggableList from '@c/library/DraggableList.vue'
import Button from '@c/library/Button.vue'
import TemplateCard from '@/views-v2/templates/TemplateCard.vue'

export default {
  name: 'TemplateEditAdd',
  components: { DraggableList, Checkbox, Button, TemplateCard },
  props: {
    currentBlock: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    step: 0,
    loading: false,
    error: false,
    templates: [],
    type: 'offering',
    selected: [],
    currentSelected: [],
    ori_types: [
      {
        key: 'offering',
        title: 'Offering assets',
        description:
          'Documents to describe your portfolio of products and services'
      },
      {
        key: 'reference',
        title: 'Case assets',
        description:
          'Assets to tell the story of your customers and how you helped them'
      },
      {
        key: 'inspiration',
        title: 'Though leadership assets',
        description:
          'Assets to share your ideas and insights with your customers'
      }
    ]
  }),
  computed: {
    isEdit() {
      return !!this.currentBlock
    },
    submitButton() {
      return this.step ? (this.isEdit ? 'Edit section' : 'Add section') : 'Next'
    },
    submitDisabled() {
      return (
        this.step &&
        (!this.selected.length || (this.isEdit && !this.selectionChanged))
      )
    },
    selectionChanged() {
      if (!this.currentBlock.selections?.length) return false
      let prev = this.currentBlock.selections.map((t) => t.template_id).join()
      let curr = this.selected.map((t) => t.uuid).join()
      return prev !== curr
    },
    selectableTemplates() {
      return this.templates.filter((t) => t.output_type === this.type)
    },
    filteredTemplates() {
      return this.selectableTemplates.filter(
        (t) => !this.selected.some((s) => s.uuid === t.uuid)
      )
    }
  },
  async created() {
    if (this.currentBlock) {
      this.type = this.currentBlock.output_type
      this.currentSelected = this.currentBlock.selections
      this.step++
    }
    await this.initTemplates()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      if (!this.step) {
        this.step++
        return
      }
      this.$emit('submit', { templates: this.selected, type: this.type })
    },
    selectType(type) {
      this.type = type.key
      this.selected = []
    },
    async initTemplates() {
      try {
        this.error = false
        this.loading = true
        this.templates = await getKeyContentTemplates({
          workspace_id: this.$route.params.workspace_id
        })
        if (this.currentBlock) {
          this.selected = this.currentSelected.map((s) =>
            this.templates.find((t) => t.uuid === s.template_id)
          )
        }
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    isPresentation(template) {
      return template.type === 'presentation' || template.file_type === 'pptx'
    },
    selectTemplate(template) {
      this.selected.push(template)
    },
    removeSelected(index) {
      this.selected.splice(index, 1)
    },
    handleReorderSelected({ from, to }) {
      const removed = this.selected[from]
      this.selected = [
        ...this.selected
          .slice(0, to > from ? to + 1 : to)
          .filter((t) => t.uuid !== removed.uuid),
        removed,
        ...this.selected
          .slice(to > from ? to + 1 : to)
          .filter((t) => t.uuid !== removed.uuid)
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.template-add {
  background: white;
  border-radius: 8px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 1.5rem;
    gap: 0.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-text {
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: auto;
    }
  }

  &-content {
    padding: 1.5rem;
    display: flex;
    flex-flow: column nowrap;

    &.first {
      padding: 1.5rem 5rem;
    }

    &:not(.first) {
      gap: 1.5rem;
    }

    &-text {
      color: #60666b;
      margin: 0 5rem;
    }

    &-selected {
      margin: 0 5rem;
      border-radius: 4px;
      background: #f1f2f3;
      border: 1px solid rgba(0, 0, 0, 0.08);
      padding: 1rem;
      gap: 0.5rem;
      max-height: 25vh;
      overflow-y: auto;

      &-item {
        display: contents;
        padding: 0.5rem 0;

        &-icon {
          height: 1.5rem;
        }

        &-name {
          margin-right: auto;
        }
      }
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      padding: 1rem;
      max-height: 25vh;
      overflow-y: auto;

      &-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        &-icon {
          height: 1.5rem;
          animation: spin 1s linear infinite;
        }
      }

      &-empty {
        width: 100%;
        text-align: center;
        padding: 1rem;
        background: rgba(#000, 0.02);
        border-radius: 4px;
        border: 1px solid rgba(#000, 0.08);
        color: #60666b;
      }
    }

    &-header {
      font-size: 1.15rem;
      font-weight: 600;
      padding-bottom: 0.75rem;
    }

    &-options {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;

      &-option {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        border-radius: 6px;
        border: 1px solid rgba(#000, 0.08);
        width: 100%;
        cursor: pointer;

        &-info {
          display: flex;
          flex-flow: column nowrap;
          gap: 0.25rem;
        }

        &:hover {
          background: rgba(#000, 0.04);
        }

        &.selected {
          border: 1px solid $primary;

          &:hover {
            background: rgba($primary, 0.04);
          }
        }

        &-radio {
          pointer-events: none;
        }

        &-title {
          font-weight: 600;
        }

        &-description {
          color: #60666b;
          font-size: 0.9rem;
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
