var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "settings",
          class: {
            padded: !_vm.$route.meta.noscroll,
            "sidebar-visible": _vm.showSidebar
          }
        },
        [
          _vm.showSidebar
            ? _c("div", { staticClass: "settings-sidebar" }, [
                _c(
                  "div",
                  { staticClass: "settings-sidebar-btns" },
                  _vm._l(_vm.tabs, function(tab) {
                    return _c(
                      "router-link",
                      { key: tab.key, attrs: { to: _vm.tabRoute(tab.key) } },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "settings-sidebar-btns-btn",
                            class: { active: _vm.currentTab === tab.key }
                          },
                          [
                            _c("img", {
                              staticClass: "settings-sidebar-btns-btn-icon",
                              attrs: {
                                src: require("@/assets/icons/" +
                                  tab.icon +
                                  ".svg"),
                                alt: ""
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "settings-sidebar-btns-btn-text" },
                              [_vm._v(_vm._s(tab.name))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              ref: "settingscontent",
              staticClass: "settings-content",
              class: { overflows: _vm.showSidebar },
              style: _vm.showSidebar ? _vm.maxHeightStyle : {}
            },
            [_c(_vm.settingsComponent, { tag: "component" })],
            1
          )
        ]
      ),
      _c("ResourceModal", {
        scopedSlots: _vm._u([
          {
            key: "subHeader",
            fn: function(ref) {
              var resource = ref.resource
              return [
                _c("PortfolioResourceModalPublish", {
                  attrs: { resource: resource }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }